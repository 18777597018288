import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectActiveMediaObject,
  selectOpenMediaObjectModal,
} from "../../../../redux/instagram-search/instagramSearch.selectors"
import { Modal, Row, Col } from "react-bootstrap"
import { closeMediaObjectModal } from "../../../../redux/instagram-search/instagramSearch.actions"
import ImportInstagramButton from "../ImportInstagramButton/ImportInstagramButton"
import ReelerButton from "../../../../components/commons/ReelerButton"
import * as S from "./MediaObjectModal.styles"
import { timeAgo } from "../../../../utils/CalculateTimeAgo"
import { FaComment, FaHeart } from "react-icons/fa"
import Text from "../../../../components/commons/Text"
import MediaObjectCarousel from "./components/MediaObjectCarousel"

export default function MediaObjectModal() {
  const openMediaObjectModal = useSelector(selectOpenMediaObjectModal)
  const activeMediaObject = useSelector(selectActiveMediaObject)

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeMediaObjectModal())
  }

  var windowFeatures = "height=650,width=900"

  function openRequestedPopup(url) {
    window.open(url, "Instagram_window", windowFeatures)
  }

  return (
    <Modal show={openMediaObjectModal} onHide={handleClose} size="xl">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <S.Media>
              {activeMediaObject?.media_type === "VIDEO" ? (
                <video
                  width="100%"
                  height="100%"
                  src={activeMediaObject?.media_url}
                  type="video/mp4"
                  controls={true}
                  style={{
                    background: "var(--reeler-white)",
                  }}
                />
              ) : activeMediaObject?.media_type === "IMAGE" ? (
                <img
                  width="100%"
                  height="100%"
                  src={activeMediaObject?.media_url}
                  style={{
                    objectFit: "contain",
                    background: "var(--reeler-white)",
                    display: "block",
                    aspectRatio: "auto",
                    overflow: "hidden",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt=""
                />
              ) : (
                <MediaObjectCarousel
                  mediaObjects={activeMediaObject?.children?.data}
                />
              )}
            </S.Media>
          </Col>
          <Col>
            <S.Body>
              <S.Caption showMore>
                <Text size="sm">{activeMediaObject?.caption}</Text>
              </S.Caption>
              <div className="d-flex flex-row justify-content-between align-items-center my-3">
                <div>
                  <Text size="sm" color="var(--reeler-icon)">
                    {timeAgo(new Date(activeMediaObject?.timestamp))}
                  </Text>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div className="me-1">
                    <FaComment className="icon-color" />
                  </div>
                  <div>
                    <Text size="sm" color="var(--reeler-icon)">
                      {activeMediaObject?.comments_count}
                    </Text>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div className="me-1">
                    <FaHeart className="icon-color" />
                  </div>
                  <div>
                    <Text size="sm" color="var(--reeler-icon)">
                      {activeMediaObject?.like_count}
                    </Text>
                  </div>
                </div>
              </div>
            </S.Body>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ReelerButton
          dispatch={handleClose}
          text="Close"
          styleClass="btn-secondary"
        />
        <ReelerButton
          styleClass="btn-secondary"
          dispatch={() => openRequestedPopup(activeMediaObject?.permalink)}
          text="View on Instagram"
        />
        {false && <ImportInstagramButton media={activeMediaObject} />}
      </Modal.Footer>
    </Modal>
  )
}
