import React, { useEffect, useState } from "react"
import { getUsernameInsights } from "../../../services/InstagamService"
import { useSelector } from "react-redux"
import { selectAccounts } from "../../../redux/account/account.selectors"
import SpinnerComponent from "../../commons/SpinnerComponent"
import formatNumber from "../../../utils/formatNumber"
import Text from "../../commons/Text"

export default function CreatorInstagramInsights({ username }) {
  const [isFetchingInsights, setIsFetchingInsights] = useState(true)
  const [insights, setInsights] = useState(null)
  const account = useSelector(selectAccounts)

  useEffect(() => {
    // Call the Instagram API to get insights
    const fetchInsights = async () => {
      setIsFetchingInsights(true)
      const instagramBussinessAccountId =
        account?.integrations?.instagram?.accounts[0]
          ?.ig_business_account_profile?.id
      const accessToken =
        account?.integrations?.instagram?.long_lived_user_token
      if (instagramBussinessAccountId && accessToken) {
        const response = await getUsernameInsights(
          instagramBussinessAccountId,
          username,
          accessToken
        )

        if (response.error) {
          setInsights(null)
          setIsFetchingInsights(false)
          return null
        }

        const businessDiscovery = response.business_discovery
        if (businessDiscovery) {
          setInsights(businessDiscovery)
        }
        setIsFetchingInsights(false)
      }
    }
    fetchInsights()
  }, [username])

  if (isFetchingInsights)
    return (
      <div className="d-flex justify-content-center w-100">
        <SpinnerComponent />
      </div>
    )
  return insights ? (
    <div className="d-flex flex-row align-items-center">
      <div className="d-flex flex-row align-items-center me-3">
        <Text size="sm">{formatNumber(insights?.media_count)} Posts</Text>
      </div>

      <div className="d-flex flex-row align-items-center">
        <Text size="sm">
          {formatNumber(insights?.followers_count)} Followers
        </Text>
      </div>
    </div>
  ) : null
}
